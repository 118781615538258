import { Buttons, theme, Inputs } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router';
import { tableStyleOverrides } from '../../api/utils/tables';
import { useActionLoader } from '../../hooks/useActionLoader';
import { fetchActionPlans } from '../../state/reducers/patient';
import { RootState } from '../../state/store';
import { IGenericActionPlan, IUser, MedicalCondition, UserAssignment } from '../../types/models';
import { PageHeader } from '../../components/common/commonStyled';
import * as S from './index.styles';
import { patchUserAssignment } from '../../state/reducers/coaching';

type IActionPlanWithIsActive = IGenericActionPlan & { isActive: boolean };

const tableColumns = [
    {
        name: 'Version',
        selector: (row: IActionPlanWithIsActive) => row.version || 'N/A',
        sortable: true
    },
    {
        name: 'Created at',
        selector: (row: IActionPlanWithIsActive) => {
            if (!row.createdAt) {
                return 'N/A';
            }
            const date = new Date(row.createdAt);
            return date.toLocaleDateString();
        },
        sortable: true
    },
    {
        name: 'Is active',
        selector: (row: IActionPlanWithIsActive) => row.isActive ? 'Active' : ''
    }
];

const conditionalRowStyles = [
    {
        when: (row: IActionPlanWithIsActive) => row.isActive,
        style: {
            color: theme.success.medium,
            fontWeight: 'bold'
        }
    },
    {
        when: (row: IActionPlanWithIsActive) => !row.isActive,
        style: {
            color: theme.shades.grey,
        }
    }
];

const ActionPlansList = () => {
    const { currentPatient: patient } = useSelector((state: RootState) => state.coaching);
    const { actionPlans } = useSelector((state: RootState) => state.patient);
    const { callAction: getActionPlans, loading, done } = useActionLoader(fetchActionPlans);
    const { callAction: updateUser } = useActionLoader(patchUserAssignment);

    const navigate = useNavigate();
    const [formattedPlans, setFormattedPlans] = useState<IActionPlanWithIsActive[]>([]);

    useEffect(() => {
        if (patient && !done && !loading) {
            getActionPlans({ patientId: patient.id });
        }
    }, [patient]);

    useEffect(() => {
        const formatted = actionPlans.map((plan: IGenericActionPlan, index: number) => {
            if (index === 0) {
                return { ...plan, isActive: true };
            }
            return { ...plan, isActive: false };
        });
        setFormattedPlans(formatted);
    }, [actionPlans]);

    const viewActionPlan = (row: IActionPlanWithIsActive) => {
        navigate(`/patients/${patient?.id}/action-plans/${row.version}`);
    };

    const updateActionPLanType = (actionPlanType: MedicalCondition) => {
        const userAssignment: UserAssignment = {
            primaryCondition: actionPlanType
        };
        updateUser({ patientId: patient?.id, userAssignment });
    };

    return (
        <S.ActionPlansListContainer>
            {patient && (
                <PageHeader>
                    <h1>Action Plans</h1>
                    <S.HeaderGroup>
                        <S.StyledSelect onChange={(e) => updateActionPLanType(e.target.value)}>
                            <option selected={patient.actionPlanType === null} disabled>Action Plan Type</option>
                            <option selected={patient.actionPlanType === MedicalCondition.COPD} value={MedicalCondition.COPD}>COPD</option>
                            <option selected={patient.actionPlanType === MedicalCondition.ASTHMA} value={MedicalCondition.ASTHMA}>Asthma</option>
                        </S.StyledSelect>
                        {patient.actionPlanType
                            ? <Buttons.LinkButton to={`/patients/${patient.id}/action-plans/new`}>New</Buttons.LinkButton>
                            : <Buttons.Button disabled>New</Buttons.Button>}
                    </S.HeaderGroup>
                </PageHeader>
            )}
            {patient && !patient.actionPlanType && (
                <p>Please select an action plan type to add action plans</p>)}
            {!loading && (
                <DataTable
                  highlightOnHover
                  striped
                  customStyles={tableStyleOverrides}
                  columns={tableColumns}
                  data={formattedPlans}
                  onRowClicked={viewActionPlan}
                  conditionalRowStyles={conditionalRowStyles}
                />
            )}
        </S.ActionPlansListContainer>
    );
};

export default ActionPlansList;
