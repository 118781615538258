import styled from 'styled-components';

export const ExerciseListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100%;
  overflow: auto;
  padding: 10px;
`;

export const ExerciseListHeaderGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ExerciseListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
`;

export const ExerciseListFiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  flex-wrap: wrap;
`;

export const Thumbnail = styled.img`
  width: 70px;
  height: 70px;
  padding: 5px;
  border-radius: 5px;
`;
