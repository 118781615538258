import React from 'react';
import * as S from './index.styles';

type Props = {
    expanded: boolean;
    onClick: () => void;
    expandedText?: React.ReactNode;
    collapsedText?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
};

const CollapseExpandButton = ({ expanded, onClick, expandedText = '- Collapse', collapsedText = '+ Expand', className, style }: Props) => {
    return (
        <S.ToggleButton
          onClick={onClick}
          buttonType="tertiary"
          className={className}
          style={style}
        >
            {expanded ? expandedText : collapsedText}
        </S.ToggleButton>
    );
};

export default CollapseExpandButton;
