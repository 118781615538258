import styled from 'styled-components';
import { Buttons } from '@apps/common-ui';

export const RecommendationField = styled.p`
    display: flex;
    align-items: center;

    svg {
        margin-right: 8px;
    }
`;

export const SummarySection = styled.div`
    width: 75%;
`;

export const LeftAlignedButton = styled(Buttons.Button)`
  align-self: flex-start;
  padding: 0;
  margin: 0;
`;
