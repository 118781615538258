import React, { useState } from 'react';
import * as S from './index.styles';
import { Modal, Buttons, Inputs } from '@apps/common-ui';

type Props = {
    onClose: () => void;
    handleSelect: (boolean) => void;
    enabled: boolean;
};

const PaymentAccessModal = ({ onClose, handleSelect, enabled }: Props) => {
    const [access, setAccess] = useState<boolean>(enabled);

    return (
        <Modal title="Healthie Payment" showModal dismissModal={onClose}>
            <S.ModalContent>
                <h3>Should this patient have access to Healthie Payments?</h3>
                <Inputs.OptionSelector
                  width="30%"
                  onSelect={setAccess}
                  value={access}
                  options={[
                    { display: 'Yes', value: true },
                    { display: 'No', value: false },
                ]}
                />
                <S.SubmitContainer>
                    <Buttons.Button
                      onClick={() => {
                            handleSelect(access);
                            onClose();
                        }}
                    >
                        Save Changes
                    </Buttons.Button>
                </S.SubmitContainer>
            </S.ModalContent>
        </Modal>
    );
};

export default PaymentAccessModal;
