import { Inputs } from '@apps/common-ui';
import styled from 'styled-components';

export const ActionPlansListContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const StyledSelect = styled(Inputs.Select)`
    max-width: 200px;
    margin: 0;
    padding: 0 5px;
`;

export const HeaderGroup = styled.div`
    display: flex;
    flex-direction: row;
`;
