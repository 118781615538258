import { theme } from '@apps/common-ui';
import styled from 'styled-components';

export const DetailsContainer = styled.div`
  width: 90%;

  span {
    margin: 0;
  }
`;

export const Header = styled.p`
  font-weight: bold;
  margin: 0;
  display: flex;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AgeText = styled.span<{color?: keyof typeof theme.tableText; isBold?: boolean }>`
    color: ${(props) => (props.color ? props.theme.tableText[props.color] : 'inherit')};
    font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
`;

export const SpinnerContainer = styled.div`
    position: relative;
    padding: 12px;
    margin-left: 8px;
`;
