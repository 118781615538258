import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const DetailsContainer = styled.div`
  width: 90%;

  span {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

export const Body = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
`;

export const CourseDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin: 0 0 20px 0;
`;

export const ModuleProgressContainer = styled.div`
  display: flex;
  width: 60px;
  height: 30px;
`;

export const NoCourseButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;
