export const currentMonth = () => new Date().toLocaleString('default', { month: 'long' });

export const currentMonthAndYear = () => `${currentMonth()} ${new Date().getFullYear()}`;

export enum BillingStateColors {
    GREY = 'grey',
    YELLOW = 'yellow',
    RED = 'red',
    BLACK = 'black'
}
