import styled from 'styled-components';

export const DetailsContainer = styled.div`
  width: 90%;

  span {
    margin: 0;
  }
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
`;
