import React from 'react';
import * as S from './index.styles';
import { PatientStatus, CareType, UserRoles } from '../../types/models';

type Props = {
    patientStatus: PatientStatus;
    careType: CareType;
    style?: React.CSSProperties;
}

const PatientStatusTag = ({ patientStatus, careType, style }: Props) => {
    let content: React.ReactNode;

    switch (patientStatus) {
        case PatientStatus.VPR_ACTIVE:
            content = (
                <>
                    <img src="./whitePlayIcon.svg" alt="play" />
                    <div>{careType} vPR</div>
                </>
            );
            break;
        case PatientStatus.VPR_PAUSED:
            content = (
                <>
                    <img src="./whitePauseIcon.svg" alt="pause" />
                    <div>{careType} vPR</div>
                </>
            );
            break;
        case PatientStatus.VPR_DISCHARGED:
            content = (
                <>
                    <img src="./whiteSquareIcon.svg" alt="stop" />
                    <div>{careType} vPR</div>
                </>
            );
            break;
        case PatientStatus.MNT_ACTIVE:
            content = (
                <>
                    <img src="./filledPlayIcon.svg" alt="play" />
                    <div>{careType} MNT</div>
                </>
            );
            break;
        case PatientStatus.MNT_PAUSED:
            content = (
                <>
                    <img src="./filledPauseIcon.svg" alt="pause" />
                    <div>{careType} MNT</div>
                </>
            );
            break;
        case PatientStatus.MNT_DISCHARGED:
            content = (
                <>
                    <img src="./filledSquareIcon.svg" alt="stop" />
                    <div>{careType} MNT</div>
                </>
            );
            break;
        default:
            content = null;
    }

    return (
        <S.PatientStatusTag
          patientStatus={patientStatus}
          style={style}
        >
            {content}
        </S.PatientStatusTag>
    );
};
export default PatientStatusTag;
