import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const HelpButton = styled(Buttons.Button)`
  white-space: nowrap;
  padding: 5px;

  .svg {
    margin-right: 5px;
    margin-left: 0;
  }
`;

export const LogoutButton = styled(Buttons.Button)`
  text-align: start;
  padding: 5px;
`;
