import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { UserRoles } from '../../../../types/models';
import PatientPageHeader from '../PatientPageHeader';
import * as S from './index.styles';
import CareManagementAndCourseRow from './components/CareManagementAndCourseRow';
import ExercisePlanProgress from './components/ExercisePlanProgress';
import HealthInfo from './components/HealthInfoRow';
import ErrorAndBillingRow from './components/ErrorAndBillingRow';
import AppointmentDates from './components/AppointmentDates';
import LinkToHealthie from '../../../../components/ExternalLinks/LinkToHealthie';
import { formatDateForPatientPage } from '../../../../utils/dateUtils';
import LinkToExternalSite from '../../../../components/ExternalLinks';
import MaintenanceRecommendations from './components/MaintenanceRecommendations';
import { Spinner } from '@apps/common-ui';

const PatientSummary = () => {
    const { coaches, currentPatient: patient } = useSelector((state: RootState) => state.coaching);
    const { userBillableRole: providerRole } = useSelector((state: RootState) => state.session);

    const careManagementExpandedState = providerRole === UserRoles.RESPIRATORY_THERAPIST
        || providerRole === UserRoles.NURSE_PRACTITIONER;
    const exerciseExpandedState = providerRole === UserRoles.PHYSICAL_THERAPIST;

    if (!patient) {
        return <Spinner />;
    }

    const { healthieId } = patient;

    const heyMarketLink = 'https://app.heymarket.com/';
    const aircallLink = 'https://aircall.io/';

    const getPrimaryCoachName = (role: UserRoles) => {
        let coachId;
        if (role === UserRoles.PHYSICAL_THERAPIST) {
            coachId = patient.primaryPhysicalTherapist?.userId;
        } else if (role === UserRoles.RESPIRATORY_THERAPIST) {
            coachId = patient.primaryRespiratoryTherapist?.userId;
        } else if (role === UserRoles.NURSE_PRACTITIONER) {
            coachId = patient.primaryNursePractitioner?.userId;
        } else {
            return '';
        }
        return coaches.find(coach => coach.userId === coachId)?.name;
    };

    const getOtherCoachesNames = () => {
        const otherCoaches: string[] = [];
        patient.coaches.forEach((coach) => {
            const name = coaches.find((c) => c.userId === coach.userId)?.name || '';
            otherCoaches.push(name);
        });
        return otherCoaches;
    };

    return (
        <>
            <PatientPageHeader patient={patient}>
                <S.ExternalLinks>
                    <LinkToHealthie healthieId={healthieId} />
                    <LinkToExternalSite url={heyMarketLink} text="HeyMarket" image="/heymarketIcon.svg" />
                    <LinkToExternalSite url={aircallLink} text="Aircall" image="/aircallIcon.png" />
                </S.ExternalLinks>
            </PatientPageHeader>
            <ErrorAndBillingRow />
            <HealthInfo />
            <S.PatientInfoRow>
                <S.PatientInfoBlock>
                    <span>Primary PT</span>
                    <p>{getPrimaryCoachName(UserRoles.PHYSICAL_THERAPIST)}</p>
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>Primary RT</span>
                    <p>{getPrimaryCoachName(UserRoles.RESPIRATORY_THERAPIST)}</p>
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>Primary NP</span>
                    <p>{getPrimaryCoachName(UserRoles.NURSE_PRACTITIONER)}</p>
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>Other</span>
                    {getOtherCoachesNames().map(name => <p key={name}>{name}</p>)}
                </S.PatientInfoBlock>
            </S.PatientInfoRow>
            <S.PatientInfoRow>
                <S.PatientInfoBlock>
                    <span>PT Appointments</span>
                    <AppointmentDates
                      nextAppointmentDate={patient.nextPTAppointmentDate ? formatDateForPatientPage(new Date(patient.nextPTAppointmentDate)) : ''}
                      lastAppointmentDate={patient.lastAttendedPTAppointmentDate ? formatDateForPatientPage(new Date(patient.lastAttendedPTAppointmentDate)) : ''}
                      missedAppointments={patient.missedPTAppointmentCount}
                    />
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>RT Appointments</span>
                    <AppointmentDates
                      nextAppointmentDate={patient.nextRTAppointmentDate ? formatDateForPatientPage(new Date(patient.nextRTAppointmentDate)) : ''}
                      lastAppointmentDate={patient.lastAttendedRTAppointmentDate ? formatDateForPatientPage(new Date(patient.lastAttendedRTAppointmentDate)) : ''}
                      missedAppointments={patient.missedRTAppointmentCount}
                    />
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>NP Appointments</span>
                    <AppointmentDates
                      nextAppointmentDate={patient.nextNPAppointmentDate ? formatDateForPatientPage(new Date(patient.nextNPAppointmentDate)) : ''}
                      lastAppointmentDate={patient.lastAttendedNPAppointmentDate ? formatDateForPatientPage(new Date(patient.lastAttendedNPAppointmentDate)) : ''}
                      missedAppointments={patient.missedNPAppointmentCount}
                    />
                </S.PatientInfoBlock>
            </S.PatientInfoRow>
            <MaintenanceRecommendations />
            <ExercisePlanProgress expandedState={exerciseExpandedState} />
            <CareManagementAndCourseRow expandedState={careManagementExpandedState} />
        </>
    );
};

export default PatientSummary;
