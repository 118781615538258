import React, { useEffect, useState } from 'react';
import * as S from './index.styles';
import { useNavigate, useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LearningTypes } from '@apps/common-utilities';
import { useModal } from '@apps/common-ui';
import RemoveModuleModal from './components/RemoveModuleModal';
import AddModuleModal from './components/AddModuleModal';
import { ApiResponse, RequestMethod, useApiRequest } from '../../hooks/useApiRequest';
import { useActionLoader } from '../../hooks/useActionLoader';
import { fetchCourseSummary, fetchModules } from '../../state/reducers/learning';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import PauseCourseModal from './components/PauseCourseModal';
import { monthDateYear } from '../../utils/dateUtils';
import toast from 'react-hot-toast';

const ManageEducationPlan = () => {
    const { patientId } = useParams<{patientId: string, courseId?: string}>();
    const { callAction: getCourseSummary, done, loading } = useActionLoader(fetchCourseSummary);
    const { modules, courseSummary } = useSelector((state: RootState) => state.learning);
    const { currentPatient: patient } = useSelector((state: RootState) => state.coaching);
    const { callAction: getModules, done: modulesFetched } = useActionLoader(fetchModules);

    const { isOpen: isRemoveModalOpen, openModal: openRemoveModal, closeModal: closeRemoveModal } = useModal();
    const { isOpen: isAddModalOpen, openModal: openAddModal, closeModal: closeAddModal } = useModal();
    const { isOpen: isPauseModalOpen, openModal: openPauseModal, closeModal: closePauseModal } = useModal();

    const navigate = useNavigate();
    const [moduleIdToRemove, setModuleIdToRemove] = useState(-1);
    const [modulesState, setModulesState] = useState<LearningTypes.IModule[]>([]);
    const [errorText, setErrorText] = useState('');

    const { callApi: fetchCourse } = useApiRequest<LearningTypes.ICourse>(RequestMethod.GET);
    const { callApi: createCourse } = useApiRequest<LearningTypes.ICourse>(RequestMethod.POST);
    const { callApi: updateCourse } = useApiRequest<LearningTypes.ICourse>(RequestMethod.PUT);

    const [course, setCourse] = useState<LearningTypes.ICourse>();

    const [expandedModuleRows, setExpandedModuleRows] = useState(new Map<number, boolean>());

    const getPatientName = () => {
        return patient ? `${patient.firstName} ${patient.lastName}` : '';
    };

    const toggleModuleRow = (moduleId: number) => {
        const newMap = new Map(expandedModuleRows);
        newMap.set(moduleId, !expandedModuleRows.get(moduleId));
        setExpandedModuleRows(newMap);
    };

    const changeModuleOrder = (oldIndex: number, newIndex: number) => {
        const newModulesState = modulesState.slice();
        newModulesState[oldIndex] = modulesState[newIndex];
        newModulesState[newIndex] = modulesState[oldIndex];
        setModulesState(newModulesState);
    };

    const removeModule = () => {
        const newModules = modulesState.filter((module) => module.id !== moduleIdToRemove);
        setModulesState(newModules);
    };

    const addModules = (modulesToAdd: LearningTypes.IModule[]) => {
        const newModules = modulesState.concat(modulesToAdd);
        setModulesState(newModules);
    };

    const refreshCourseInfo = async () => {
        fetchCourse(`/coaches/users/${patientId}/education/course-assignments/${course?.id}`).then((response) => {
            setCourse(response.response.data);
        });
    };

    const createAndAssignCourse = async () => {
        if (patientId && modulesState.length > 0) {
            const body: LearningTypes.ICreateCourse = {
                userId: patientId,
                moduleIds: modulesState.map((module) => module.id),
            };

            createCourse(`/coaches/users/${patientId}/education/course-assignments`, body).then(({ response, error }: ApiResponse<LearningTypes.ICourse>) => {
                if (!!error.error || !!error.message) {
                    toast.error(`Failed to create course. ${error.error || error.message}`, { duration: 5000 });
                } else if (response.data) {
                    setCourse(response.data);
                    toast.success('Course created successfully', { duration: 5000 });
                }
            });
        } else if (modulesState.length === 0) {
            setErrorText('Please add modules to the course before assigning it');
        }
    };

    const updateExistingCourse = async () => {
        if (patientId && course && modulesState.length > 0) {
            const body: LearningTypes.ICreateCourse = {
                userId: patientId,
                moduleIds: modulesState.map((module) => module.id)
            };
            updateCourse(`/coaches/users/${patientId}/education/course-assignments/${course.id}`, body).then(({ response, error }: ApiResponse<LearningTypes.ICourse>) => {
                if (!!error.error || !!error.message) {
                    toast.error(`Failed to update course. ${error.error || error.message}`, { duration: 5000 });
                } else if (response.data) {
                    setCourse(response.data);
                    toast.success('Course updated successfully', { duration: 5000 });
                }
            });
        }
    };

    useEffect(() => {
        if (course) {
            expandedModuleRows.clear();
            course?.modules.forEach((module) => {
                if (module.id) {
                    expandedModuleRows.set(module.id, false);
                }
            });

            // set the default module state of the course
            const defaultModuleState: LearningTypes.IModule[] = [];
            course.modules.forEach((moduleInfo) => {
                const moduleToAdd = modules.find((module) => module.id === moduleInfo.id);
                if (moduleToAdd) {
                    defaultModuleState.push(moduleToAdd);
                }
            });
            setModulesState(defaultModuleState);
        }
    }, [course]);

    useEffect(() => {
        getModules();
        if (courseSummary && courseSummary.courseUserAssignmentId) {
            fetchCourse(`/coaches/users/${patientId}/education/course-assignments/${courseSummary.courseUserAssignmentId}`).then((response) => {
                setCourse(response.response.data);
            });
        }
    }, [courseSummary, patientId]);

    useEffect(() => {
        if (!courseSummary || !courseSummary.courseUserAssignmentId) {
            getCourseSummary(patientId);
        }
    }, [patientId]);

    useEffect(() => {
        if (modulesState.length > 0 && errorText) {
            setErrorText('');
        }
    }, [modulesState, errorText]);

    return (
        <>
            <PauseCourseModal
              showModal={isPauseModalOpen}
              dismissModal={closePauseModal}
              courseId={course?.id || 0}
              isPaused={course?.isPaused || false}
              userId={patientId || ''}
              refreshCourse={refreshCourseInfo}
            />
            <RemoveModuleModal
              showModal={isRemoveModalOpen}
              dismissModal={closeRemoveModal}
              moduleId={moduleIdToRemove}
              removeModule={removeModule}
            />
            <AddModuleModal
              showModal={isAddModalOpen}
              dismissModal={closeAddModal}
              modulesAlreadyInCourse={modulesState}
              addModules={addModules}
            />
            <S.Container>
                <S.PageTitle>Education Plan</S.PageTitle>
                <S.SubtitleRow>
                    <S.DetailsContainer>
                        <S.PatientName>{getPatientName()}</S.PatientName>
                        {!!course?.updatedDate && <S.LastUpdated>Last updated: {monthDateYear(new Date(course?.updatedDate))}</S.LastUpdated>}
                    </S.DetailsContainer>
                    <S.ButtonContainer>
                        {!!course?.id && (
                        <S.ManageButton
                          onClick={() => {
                                openPauseModal();
                            }}
                        >
                            Manage
                        </S.ManageButton>
                        )}
                        <S.AssignPlanButton
                          canAssign={!!modulesState.length}
                          onClick={() => {
                                    if (course) {
                                        updateExistingCourse();
                                    } else {
                                        createAndAssignCourse();
                                    }
                                }}
                        >
                            Assign Plan
                        </S.AssignPlanButton>
                    </S.ButtonContainer>
                </S.SubtitleRow>
                {!course && !modulesState.length
                    && (
                        <S.NoModulesRow>
                            <S.NoModulesText>No Modules</S.NoModulesText>
                        </S.NoModulesRow>
                    )}
                <div>
                    {(!!course || !!modulesState.length) && modulesState.map((module, index) => (
                        <>
                            <S.ModuleRow key={module.id}>
                                <S.ModuleRowStartContainer>
                                    <S.PositionButtonContainer>
                                        <S.ModulePositionButton
                                          isFirstOrLast={index === 0}
                                          onClick={() => {
                                                if (index !== 0) {
                                                    changeModuleOrder(index, index - 1);
                                                }
                                              }}
                                        >
                                            <FontAwesomeIcon icon={faChevronUp as IconProp} />
                                        </S.ModulePositionButton>
                                        <S.ModulePositionButton
                                          isFirstOrLast={index === modulesState.length - 1}
                                          onClick={() => {
                                                    if (index !== modulesState.length - 1) {
                                                        changeModuleOrder(index, index + 1);
                                                    }
                                                }}
                                        >
                                            <FontAwesomeIcon icon={faChevronDown as IconProp} />
                                        </S.ModulePositionButton>
                                    </S.PositionButtonContainer>
                                    <S.ModuleImage src={module.thumbnailUrl} />
                                    <S.ModuleTitle>{module.title}</S.ModuleTitle>
                                </S.ModuleRowStartContainer>
                                <S.ModuleRowEndContainer>
                                    <S.RemoveButton
                                      onClick={() => {
                                                    setModuleIdToRemove(Number(module.id));
                                                    openRemoveModal();
                                            }}
                                    >
                                        Remove
                                    </S.RemoveButton>
                                    <S.ModuleDetailsButton
                                      onClick={() => toggleModuleRow(Number(module.id))}
                                    >
                                        {expandedModuleRows.get(Number(module.id)) ? '-' : '+'} Module Details
                                    </S.ModuleDetailsButton>
                                </S.ModuleRowEndContainer>
                            </S.ModuleRow>
                            {!!expandedModuleRows.get(Number(module.id)) && (
                                <S.SubmoduleContainer>
                                    {module.subModuleTitles.map((submoduleTitle, submoduleIndex) => (
                                        <S.SubmoduleRow key={submoduleTitle}>
                                            <S.SubmoduleNumber>{submoduleIndex}</S.SubmoduleNumber>
                                            <S.SubmoduleTitle>{submoduleTitle}</S.SubmoduleTitle>
                                        </S.SubmoduleRow>
                                    ))}
                                </S.SubmoduleContainer>
                                )}
                        </>
                    ))}
                </div>
                <S.BottomButtonContainer>
                    <S.AddModuleButton
                      onClick={() => openAddModal()}
                    >
                        + Add Module
                    </S.AddModuleButton>
                </S.BottomButtonContainer>
                {!!errorText.length && (
                <S.ErrorContainer>
                    <S.ErrorText>{errorText}</S.ErrorText>
                </S.ErrorContainer>
                )}
            </S.Container>
        </>
    );
};
export default ManageEducationPlan;
