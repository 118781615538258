import { format } from 'date-fns';
import { PlanOfCareDateColor } from '../pages/PatientsList/components/tableUtils';
import { da } from 'date-fns/locale';

export const monthDateYear = (date: Date) => {
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
};

// Date object without timezone information
export class NaiveDate extends Date {
    constructor(date: string) {
        const year = parseInt(date.substring(0, 4), 10);
        const month = parseInt(date.substring(5, 7), 10) - 1;
        const day = parseInt(date.substring(8, 11), 10);
        super(year, month, day);
    }

    setTimeToMidnight() {
        this.setUTCHours(0, 0, 0, 0);
        return this;
    }
}

// converts date object to format that can be used in input type="date"
// e.g. 2021-01-01
export const formatDateForInput = (date: Date) => {
    // account for timezone differences
    date.setDate(date.getUTCDate());

    return `${format(date, 'yyyy-MM-dd')}`;
};

// converts date object to format that can be used in input type="datetime-local"
// e.g. 2021-01-01T00:00
export const formatDateTimeForInput = (date: Date) => {
    // account for timezone differences
    date.setDate(date.getUTCDate());

    return `${format(date, 'yyyy-MM-dd')}T00:00`;
};

// formats date object to be displayed on the patient page
// e.g. Jan 1 2021
export const formatDateForPatientPage = (date: Date) => {
    return format(date, 'MMM do yyyy');
};

// formats date object to be displayed on the patient list
// e.g. Jan 1
export const formatDateForPatientList = (date: Date) => {
    return format(date, 'MMM d');
};

export const getDaysFromDate = (endDate: Date) => {
    // negative if in the past, positive if in the future
    const diff = Math.ceil((endDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    return Object.is(diff, -0) ? 0 : diff; // Normalize -0 to 0
};

export const daysOld = (date: Date) => {
    const today = new Date();
    const timeDifference = today.getTime() - date.getTime(); // Difference in milliseconds
    return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
};

export const monthsOld = (date: Date) => {
    const today = new Date();
    return (today.getFullYear() - date.getFullYear()) * 12 + today.getMonth() - date.getMonth();
};

export const getColorForPlanOfCare = (isPT: boolean, date: string) => {
    let daysUntilDate = getDaysFromDate(new Date(date));
    if (isPT) {
        if (daysUntilDate <= 7) {
            return PlanOfCareDateColor.RED;
        } else if (daysUntilDate <= 14) {
            return PlanOfCareDateColor.YELLOW;
        } else {
            return PlanOfCareDateColor.BLACK;
        }
    }
    // If not PT, we want to show the date in red if it's over a year old
    daysUntilDate = -daysUntilDate;
    // 11.75 months away is red
    if (daysUntilDate >= 357) {
        return PlanOfCareDateColor.RED;
    // 11 to 11.75 months away is yellow
    } else if (daysUntilDate >= 334) {
        return PlanOfCareDateColor.YELLOW;
    } else {
        return PlanOfCareDateColor.BLACK;
    }
};

export const isBoldForPlanOfCare = (isPT: boolean, date: string) => {
    if (isPT) {
        return getDaysFromDate(new Date(date)) <= 14;
    } else {
        // 11 months old is bold
        return -getDaysFromDate(new Date(date)) >= 334;
    }
};

export const showPlanOfCareWarning = (isPT: boolean, date: string) => {
    if (isPT) {
        return getDaysFromDate(new Date(date)) <= 0;
    } else {
        return monthsOld(new Date(date)) > 12;
    }
};
