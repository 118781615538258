import React from 'react';
import * as S from './index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faCalendar, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IMaintenanceRecommendation, MaintenanceRecommendationType, UserRoles } from '../../../../../../../types/models';
import { maintenanceRecVisitFrequencyOptionsDisplay } from '@apps/common-utilities/src/types/labels';

interface Props {
    maintenanceType: MaintenanceRecommendationType;
    maintenanceRecFormData: IMaintenanceRecommendation | null;
    openModalFunction: () => void;
    userBillableRole: UserRoles | null;
}

const MaintenanceRecommendationSummary = ({ maintenanceType, maintenanceRecFormData, openModalFunction, userBillableRole }: Props) => {
    return (
        <>
            <S.SummarySection>
                <span>
                    {maintenanceType} Maintenance Recommendation
                </span>
                {maintenanceRecFormData && (
                    <>
                        {maintenanceRecFormData.recommended && (
                            <>
                                <S.RecommendationField>
                                    <FontAwesomeIcon icon={faUserGroup as IconProp} size="xs" />
                                    {maintenanceRecVisitFrequencyOptionsDisplay(maintenanceRecFormData.visitFrequency)} visits
                                </S.RecommendationField>
                                <S.RecommendationField>
                                    <FontAwesomeIcon icon={faClockRotateLeft as IconProp} size="xs" />
                                    {maintenanceRecFormData.visitLengthMinutes} min visits
                                </S.RecommendationField>
                                {maintenanceType === MaintenanceRecommendationType.PT && (
                                <S.RecommendationField>
                                    <FontAwesomeIcon icon={faCalendar as IconProp} size="xs" />
                                    {maintenanceRecFormData.durationMonths} month program
                                </S.RecommendationField>
                                )}
                            </>
                        )}
                        {!maintenanceRecFormData.recommended && (
                            <S.RecommendationField>
                                Not Recommended
                            </S.RecommendationField>
                        )}
                    </>
                )}
            </S.SummarySection>
            {
                ((userBillableRole === UserRoles.PHYSICAL_THERAPIST && maintenanceType === MaintenanceRecommendationType.PT)
                || (userBillableRole === UserRoles.RESPIRATORY_THERAPIST && maintenanceType === MaintenanceRecommendationType.PCM)) ? (
                    <S.LeftAlignedButton buttonType="tertiary" onClick={() => openModalFunction()}>
                        {maintenanceRecFormData ? 'Edit' : '+Add'}
                    </S.LeftAlignedButton>
                    ) : !maintenanceRecFormData && (
                    <S.RecommendationField>
                        No Recommendation Yet
                    </S.RecommendationField>
                    )
            }
        </>

    );
};

export default MaintenanceRecommendationSummary;
