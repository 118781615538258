import styled from 'styled-components';
import { Buttons } from '@apps/common-ui';

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
`;

export const SubmitContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    align-self: flex-end;
    margin-top: 20px;
`;
