import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';
import { PatientInfoBlock, PatientInfoRow } from '../../index.styles';

export const RecommendationField = styled.p`
    display: flex;
    align-items: center;

    svg {
        margin-right: 5px;
    }
`;

export const LeftAlignedButton = styled(Buttons.Button)`
  align-self: flex-start;
  padding: 0;
  margin: 0;
`;

export const MaintenanceInfoBlock = styled(PatientInfoBlock)`    
`;
