import styled from 'styled-components';
import { theme, Buttons, Inputs } from '@apps/common-ui';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  align-self: flex-start; 
`;

export const Title = styled.h1`
  margin: 0;
  font-weight: 450;
`;

export const Subtitle = styled.p`
  margin: 0;
`;

export const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start; 
    gap: 0;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px; 
  padding: 5px 15px;
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionLabel = styled.label`
  font-weight: bold;
  margin-bottom: 10px; 
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SaveButton = styled(Buttons.Button)`
  align-self: flex-start; 
  background-color: ${({ disabled }) => (disabled ? theme.buttons.grey : theme.buttons.blue)};
  color:  ${theme.text.white};
  border: none;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const MonthsSelect = styled.select`
  width: 40%;
  height: 40px;
  background-color: ${theme.shades.lighterGrey};
  border: 1px solid ${theme.shades.lighterGrey};
  border-radius: 4px;
  padding: 10px 10px;
`;
