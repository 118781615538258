import { Buttons, useConfirmDialog } from '@apps/common-ui';
import React, { createContext, ReactNode, useEffect, useMemo, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useActionLoader } from '../../hooks/useActionLoader';
import { createActionPlan } from '../../state/reducers/patient';
import { RootState } from '../../state/store';
import { defaultActionPlan, defaultCopdActionPlan } from '../../types/defaults';
import { IGenericActionPlan, IUser, MedicalCondition } from '../../types/models';
import { PageHeader } from '../common/commonStyled';
import AsthmaActionPlan from './AsthmaActionPlan';
import CopdActionPlan from './CopdActionPlan';
import * as S from './index.styles';
import { reducer, ReducerActionType } from './reducer';
import UnsavedChangesIcon from '../UnsavedChangesIcon';

export const ActionPlanContext = createContext<{
    actionPlan: IGenericActionPlan,
    updateActionPlan:({ type, payload }: { type: ReducerActionType, payload: any }) => void
        }>({
            actionPlan: defaultActionPlan as IGenericActionPlan,
            updateActionPlan: () => {}
        });

/**
 * A generic action plan context provider that can provide context based on the users condition
 */
const ActionPlanProvider = ({ children, defaultPlan, savePlan }:
    { children: ReactNode, defaultPlan: IGenericActionPlan, savePlan: (plan: IGenericActionPlan) => void }) => {
    const [actionPlan, updateActionPlan] = useReducer(reducer, defaultPlan);
    const [changesMade, setChangesMade] = useState(false);
    const { ConfirmationDialog, confirm } = useConfirmDialog({
        message:
        'Are you sure you want to save your changes? This will become the active action plan for this patient.'
    });

    const navigate = useNavigate();

    // Wrapper lets us track when changes have been made
    const updatePlanWrapper = ({ type, payload }: { type: ReducerActionType, payload: any }) => {
        setChangesMade(true);
        updateActionPlan({
            type,
            payload
        });
    };

    const contextValue = useMemo(() => ({
        actionPlan,
        updateActionPlan: updatePlanWrapper
    }), [defaultPlan, updateActionPlan]);

    const confirmSave = () => {
        confirm().then((confirmed) => {
            if (confirmed) {
                savePlan(actionPlan);
            }
        });
    };

    const cancel = () => {
        navigate(-1);
    };

    return (
        <ActionPlanContext.Provider value={contextValue}>
            <ConfirmationDialog />
            {changesMade && (
                <UnsavedChangesIcon text="Unsaved changes" />
            )}
            {!actionPlan.isActive && (
                <UnsavedChangesIcon text="This action plan is archived" />
            )}
            {children}
            <S.ActionPlanDivider />
            <S.ActionsContainer>
                <Buttons.Button onClick={confirmSave} disabled={!actionPlan.isActive}>Save</Buttons.Button>
                <Buttons.Button onClick={cancel} buttonType="secondary">Cancel</Buttons.Button>
            </S.ActionsContainer>
        </ActionPlanContext.Provider>
    );
};

/**
 * Show the relevant action plan based on the users condition.
 * Works for both new and existing action plans
 */
const ActionPlan = () => {
    const { patient } = useOutletContext<{ patient: IUser }>();
    const { actionPlanVersion } = useParams();
    const { actionPlans } = useSelector((state: RootState) => state.patient);
    const [actionPlan, setActionPlan] = useState<IGenericActionPlan>();
    const { user } = useSelector((state: RootState) => state.session);
    const { callAction: createPlan, done } = useActionLoader(createActionPlan, {
        successMessage: 'Action plan created',
        errorMessage: 'Error creating action plan'
    });
    const [isActiveActionPlan, setIsActiveActionPlan] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (actionPlans && actionPlanVersion) {
            const editingPlan = actionPlans.find(plan => plan.version === parseInt(actionPlanVersion, 10));
            if (editingPlan) {
                setActionPlan(editingPlan);
            }
        } else if (patient.primaryDiagnosis === MedicalCondition.COPD) {
            setActionPlan(defaultCopdActionPlan);
        } else {
            setActionPlan(defaultActionPlan);
        }
        setIsActiveActionPlan(
            actionPlanVersion === actionPlans.map(a => a.version).sort().pop()?.toString()
        );
    }, [actionPlanVersion, actionPlans]);

    useEffect(() => {
        if (done) {
            navigate(`/patients/${patient.id}/action-plans`);
        }
    }, [done]);

    const saveActionPlan = (plan: IGenericActionPlan) => {
        createPlan({
            coachId: user?.id,
            patientId: patient.id,
            actionPlan: plan
        });
    };

    return (
        <S.ActionPlanContainer>
            <div>
                <S.ActionPlanHeader>{patient.firstName} {patient.lastName}</S.ActionPlanHeader>
                <S.ActionPlanHeader>Action Plan</S.ActionPlanHeader>
            </div>
            <S.ActionPlanDivider />
            {actionPlan && ((patient && patient.primaryDiagnosis === MedicalCondition.COPD)
                ? (
                    <ActionPlanProvider
                      defaultPlan={actionPlan}
                      savePlan={saveActionPlan}
                    >
                        <CopdActionPlan />
                    </ActionPlanProvider>
                )
                : (
                    <ActionPlanProvider
                      defaultPlan={actionPlan}
                      savePlan={saveActionPlan}
                    >
                        <AsthmaActionPlan />
                    </ActionPlanProvider>
                ))}
        </S.ActionPlanContainer>
    );
};

export default ActionPlan;
