import styled from 'styled-components';

export const PageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    border-bottom: 1px solid ${props => props.theme.shades.lightGrey};
`;

export const HeaderName = styled.h2`
    margin: 5px;
`;

export const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
`;

export const PatientInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HeaderRow = styled.div`
    display: flex;
`;

export const StatusTags = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 20px;
`;

export const ContactInfo = styled.div`
    display: flex;
    gap: 30px;
`;

export const FullLegalName = styled.p`
    margin: 3px;
`;

export const ContactItem = styled.p`
    margin: 3px;
`;
