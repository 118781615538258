import { useSelector } from 'react-redux';
import { useActionLoader } from '../../../../hooks/useActionLoader';
import { fetch98976CurrentPeriodSummary } from '../../../../state/reducers/billing';
import * as S from './index.styles';
import { RootState } from '../../../../state/store';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { UserRoles } from '../../../../types/models';

export enum DayCountColors {
    RED = 'RED',
    YELLOW = 'YELLOW',
    GREEN = 'GREEN'
}

const Current98976Summary = ({ expanded }: {expanded: boolean }) => {
    const { patientId } = useParams();
    const { user: provider } = useSelector((store: RootState) => store.session);
    const [isPT, setIsPT] = useState(false);
    const { callAction: get98976Summary, done: current98976SummaryDone, loading: current98976SummaryLoading } = useActionLoader(fetch98976CurrentPeriodSummary);
    const { current98976PeriodSummary } = useSelector((state: RootState) => state.billing);

    const getDayCountColor = () => {
        if (current98976PeriodSummary.daysWith98976Data >= 16) {
            return DayCountColors.GREEN;
        } else if (current98976PeriodSummary.daysWith98976Data >= 10) {
            return DayCountColors.YELLOW;
        }
        return DayCountColors.RED;
    };

    const getMonthDayString = (date: Date) => {
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        return `${month} ${day}`;
    };

    const getDaysAwayText = () => {
        return `more day${16 - current98976PeriodSummary.daysWith98976Data !== 1 ? 's' : ''} needed in the active 30 day rolling period`;
    };

    useEffect(() => {
        if (provider?.roles.find(role => role.billable)?.role === UserRoles.PHYSICAL_THERAPIST) {
            setIsPT(true);
        }
    }, [provider]);

    useEffect(() => {
        if (!current98976SummaryDone && !current98976SummaryLoading) {
            get98976Summary(patientId);
        }
    }, [patientId, current98976SummaryDone, current98976SummaryLoading]);

    return (
        <S.SummaryContainer expanded={expanded}>
            <S.SummaryHeader>
                Past Activity
            </S.SummaryHeader>
            <S.Current98976Summary>
                <S.DaysCount color={getDayCountColor()}>
                    <S.DaysCountText>{current98976PeriodSummary.daysWith98976Data}/16 Days</S.DaysCountText>
                </S.DaysCount>
                <S.DaysAway color={getDayCountColor()}>
                    <S.DaysRemainingCount color={getDayCountColor()}>{current98976PeriodSummary.daysWith98976Data <= 16 ? 16 - current98976PeriodSummary.daysWith98976Data : 0}</S.DaysRemainingCount>
                    <S.DaysRemainingText>{getDaysAwayText()}</S.DaysRemainingText>
                </S.DaysAway>
                <S.PeriodDateRow color={getDayCountColor()}>
                    {current98976PeriodSummary.daysWith98976Data < 16 && (
                    <>
                        <S.PeriodDate>{getMonthDayString(current98976PeriodSummary.currentPeriodStartDate)}</S.PeriodDate>
                        <S.PeriodDateText>Active Period Start Date</S.PeriodDateText>
                    </>
                    )}
                    {current98976PeriodSummary.daysWith98976Data >= 16 && !!current98976PeriodSummary.nextPeriodStartDate && (
                        <>
                            <S.PeriodDate>{getMonthDayString(current98976PeriodSummary.nextPeriodStartDate)}</S.PeriodDate>
                            <S.PeriodDateText>Next Period Start Date</S.PeriodDateText>
                        </>
                    )}
                </S.PeriodDateRow>
                <S.PeriodDateRow>
                    {!!current98976PeriodSummary.previousPeriodEndDate && current98976PeriodSummary.daysWith98976Data < 16 && (
                    <>
                        <S.PeriodDate>{getMonthDayString(current98976PeriodSummary.previousPeriodEndDate)}</S.PeriodDate>
                        <S.PeriodDateText>Previous Period End Date</S.PeriodDateText>
                    </>
                    )}
                    {!current98976PeriodSummary.previousPeriodEndDate && current98976PeriodSummary.daysWith98976Data < 16 && (
                    <>
                        <S.PeriodDate>N/A</S.PeriodDate>
                        <S.PeriodDateText>No Previous Period</S.PeriodDateText>
                    </>
                    )}
                    {current98976PeriodSummary.daysWith98976Data >= 16 && !!current98976PeriodSummary.currentPeriodEndDate && (
                        <>
                            <S.PeriodDate>{getMonthDayString(current98976PeriodSummary.currentPeriodEndDate)}</S.PeriodDate>
                            <S.PeriodDateText>Current Period End Date</S.PeriodDateText>
                        </>
                    )}
                </S.PeriodDateRow>
                {current98976PeriodSummary.daysWith98976Data >= 16 && current98976PeriodSummary.nextPeriodStartDate && (
                <S.NextPeriodStartRow>
                    <S.NextPeriodStartText>Next period begins on {getMonthDayString(current98976PeriodSummary.nextPeriodStartDate)}, {current98976PeriodSummary.nextPeriodStartDate.getFullYear()}</S.NextPeriodStartText>
                </S.NextPeriodStartRow>
                )}
            </S.Current98976Summary>
        </S.SummaryContainer>
    );
};

export default Current98976Summary;
