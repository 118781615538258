import styled from 'styled-components';
import { Buttons } from '@apps/common-ui';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

export const HeaderRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;

export const Header = styled.p`
  font-weight: bold;
  margin: 0;
`;

export const ManageButton = styled(Buttons.Button)`
    margin: 0;
    width: 110px;
    height: 50px;
    text-align: right;
`;

export const InfoRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
`;
