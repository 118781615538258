import styled from 'styled-components';

export const LinkImg = styled.img`
    width: 20px;
    object-fit: contain;
    object-position: left;
`;

export const LinkRow = styled.a`
    display: flex;
    align-items: center;
`;

export const LinkArrow = styled.img`
    width: 10px;
    height: 10px;
    object-fit: contain;
`;

export const LinkText = styled.p`
    margin: 0px 10px;
    padding: 0px;
    font-weight: bold;
    text-decoration: none;
`;
