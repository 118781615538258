import { ActionplanInputType, ActionPlanZoneType, IGenericActionPlan, PhlegmColor } from './models';

export const defaultActionPlan: IGenericActionPlan = {
    lang: 'English',
    userId: null,
    coachId: null,
    createdBy: null,
    providerName: '',
    phoneNumber: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    published: false,
    isActive: true,
    createdAt: new Date().toISOString(),
    actionPlanZones: [
        {
            zone: ActionPlanZoneType.GREEN,
            zoneItems: [
                {
                    inputType: ActionplanInputType.MEDICATION,
                    value: '',
                }
            ],
            minFlowRate: 0,
            maxFlowRate: 0
        },
        {
            zone: ActionPlanZoneType.YELLOW,
            zoneItems: [
                {
                    inputType: ActionplanInputType.MEDICATION,
                    value: '',
                },
                {
                    inputType: ActionplanInputType.ACTION,
                    value: '',
                }
            ],
            minFlowRate: 0,
            maxFlowRate: 0
        },
        {
            zone: ActionPlanZoneType.RED,
            zoneItems: [
                {
                    inputType: ActionplanInputType.MEDICATION,
                    value: '',
                },
                {
                    inputType: ActionplanInputType.ACTION,
                    value: '',
                }
            ],
            minFlowRate: 0,
            maxFlowRate: 0
        }
    ]
};

export const defaultCopdActionPlan = {
    ...defaultActionPlan,
    copdInfo: {
        shortnessOfBreathCauses: [
            {
                cause: ''
            }
        ],
        phlegmColour: PhlegmColor.CLEAR
    }
};
