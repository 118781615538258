import React from 'react';
import * as S from './index.styles';

const LinkToExternalSite = ({ url, text, image }: { url: string; text: string; image: string }) => (
    <S.LinkRow href={url} target="_blank" rel="noreferrer">
        <S.LinkImg src={image} alt={text} />
        <S.LinkText>{text}</S.LinkText>
        <S.LinkArrow src="/LinkArrow.svg" alt="Link Arrow" />
    </S.LinkRow>
);

export default LinkToExternalSite;
