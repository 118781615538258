import React, { useEffect, useState } from 'react';
import * as SParent from '../../index.styles';
import * as S from './index.styles';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../state/store';
import { fetchActionPlans } from '../../../../../../../../state/reducers/patient';
import { useActionLoader } from '../../../../../../../../hooks/useActionLoader';
import { format } from 'date-fns';
import { formatDateForPatientPage } from '../../../../../../../../utils/dateUtils';

const ActionPlan = () => {
    const { patientId } = useParams<{ patientId: string }>();
    const { actionPlans } = useSelector((state: RootState) => state.patient);
    const { callAction: getActionPlans, done, loading } = useActionLoader(fetchActionPlans);
    const navigate = useNavigate();
    const firstActionPlan = actionPlans[actionPlans.length - 1];
    const lastActionPlan = actionPlans[0];

    useEffect(() => {
        if (patientId && !done && !loading) {
            getActionPlans({ patientId });
        }
    }, [patientId]);

    return (
        <SParent.Container>
            <SParent.HeaderRow>
                <SParent.Header>Action Plan</SParent.Header>
                <SParent.ManageButton
                  buttonType="tertiary"
                  onClick={() => navigate(`/patients/${patientId}/action-plans`)}
                >
                    Manage
                </SParent.ManageButton>
            </SParent.HeaderRow>
            <S.Body>
                {firstActionPlan && <span>Created on {formatDateForPatientPage(new Date(firstActionPlan.createdAt))} </span>}
                {lastActionPlan && <span>Last updated on {formatDateForPatientPage(new Date(lastActionPlan.createdAt))} </span>}
            </S.Body>
        </SParent.Container>
    );
};

export default ActionPlan;
